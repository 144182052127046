import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  styled,
  MenuItem,
  TextField,
  TableRow,
  Autocomplete,
  Menu,
  FormControlLabel,
  Checkbox,
  Tooltip,
  CircularProgress
} from "@mui/material";
import MethodApi from "../../api/LIMS/Method";
import MethodFacilityApi from "../../api/LIMS/MethodFacility";
import TestResultApi from "../../api/LIMS/TestResult";
import MethodResultComponentApi from "../../api/LIMS/MethodResultComponent";
import UnitOfMeasureApi from "../../api/Admin/UnitOfMeasure";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell, UXDataTableNested } from "../../components/UXDataTable";
import MoreOptions from "@mui/icons-material/MoreVert";
import AttachFile from "@mui/icons-material/AttachFile";
import Cancel from "@mui/icons-material/Cancel";
import TestApi from '../../api/LIMS/Test';
import { getMessage, isNumber, formatMidasNumber, downloadFile } from "../../global";
import IconButton from '@mui/material/IconButton';
import ModalSimpleButton from '../../components/Modal/ModalSimpleButton';
import ModalTwoButtons from '../../components/Modal/ModalTwoButtons';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DownloadIcon from '@mui/icons-material/FileDownload';

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const StyledChildInput = styled(TextField)(() => ({
  width: "100%"
}));

const StyledBox = styled(Box)(() => ({
  display: 'inline-box',
  marginRight: "25px"
}));

const StyledBodyModalEmail = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginTop: '5px'
}));

const StyledContainerAttach = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const StyledTextField = styled(TextField)(() => ({
  width: "20rem"
}));

const StyledButton = styled(Button)(() => ({
  width: '200px',
  textTransform: 'none',
  height: '2.2rem'
}));

const SimpleResultEntry = ({ ...props }) => {
  const [testRowOpen, setSampleRowOpen] = useState([])

  const searchCategory = [
    {
      name: "",
      id: 0
    },
    {
      name: "Method",
      id: 1
    },
    {
      name: "MIDAS Number",
      id: 2
    },
  ]

  const searchCriteriaInitialState = {
    methodName: "",
    facility: "",
    sampleName: "",
    submitterEmail: "",
    requesterEmail: "",
    ownerEmail: "",
    testOwnerEmail: "",
    condition: "",
    showPending: false
  }

  const validationsInitialState =
  {
    methodName: "",
    sampleName: "",
    facility: "",
  };

  const pointTestResultInitialState =
  {
    resultComponentName: "",
    resultText: "",
    resultValue: null,
    resultComment: "",
    testResultID: 0,
    uoMName: "",
  };

  const testResultInitialState =
  {
    testID: null,
    id: 0,
    resultDisplayOrder: 0,
    pointTestResult: pointTestResultInitialState
  };

  const [searchCategorySelected, setSearchCategorySelected] = React.useState(null);
  const [searchCriteria, setSearchCriteria] = React.useState(searchCriteriaInitialState);
  const [methodList, setMethodList] = React.useState(null);
  const [listTestResult, setListTestResult] = React.useState([]);
  const [uoMs, setUoMs] = React.useState([]);
  const [methodFacilities, setMethodFacilities] = React.useState(null);
  const [listTest, setListTest] = React.useState([]);
  const [invalidField, setInvalidField] = React.useState(validationsInitialState);
  const [listTestAttach, setListTestAttach] = React.useState([]);
  const [listMethodResult, setListMethodResult] = React.useState([]);
  const [conditionList, setConditionList] = React.useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = React.useState(null);

  const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
  const [modalSimpleText, setModalSimpleText] = React.useState('');

  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = React.useState(false);
  const [messageSubmitter, setMessageSubmitter] = React.useState(null);
  const [errorSave, setErrorSave] = React.useState(false);

  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [showRequired, setShowRequired] = useState(false);
  const [isButton1Disabled, setIsButton1Disabled] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const simpleResultColHeaders = ['Age', 'Method', 'Condition', 'MIDAS Number','Estimate', 'Container', 'TSN', 'Results', 'Comments', ''];
  const subColHeaders = ['Result Component Name', '', 'Value', 'UoM', 'Result Remark']

  async function handleSearch() {
    if (validateFields()) {
      setDataIsLoading(true)
      setListTest([]);
      setRowsPerPage(10);
      setPage(0);
      await TestApi.getSimpleResult(
        searchCriteria.methodName,
        searchCriteria.facility,
        searchCriteria.sampleName,
        searchCriteria.condition ? searchCriteria.condition : "").then((res) => {
          if (res.length > 0) {
            let newListTestResult = [];
            let newListTestAttach = [];

            const tests = res.map((el, index) => {
              if (el.testResults && el.testResults.length > 0) {
                newListTestResult = [
                  ...newListTestResult,
                  ...el.testResults.filter(t => t.blobTestResult === null && t.pointTestResult !== null)
                ]
              }

              const attachs = el.testResults.filter(t => t.blobTestResult !== null)

              if (attachs.length > 0) {
                const attach = {
                  testID: el.id,
                  files: attachs.map(item => {
                    return {
                      file: {
                        ...item.blobTestResult,
                        name: item.blobTestResult.fileName,
                      },
                      testResultID: item.blobTestResult.testResultID,
                      isHighContent: item.blobTestResult.isHighContent
                    }
                  })
                }
                newListTestAttach.push(attach)
              }

              el.testResults = [];
              return {
                ...el
              }
            })

            setListTest(tests);
            setListTestResult([...newListTestResult])
            setListTestAttach([...newListTestAttach])

            loadMethodResult(tests)
          }
          else {
            setListTestResult([])
            setListTest([]);
            setListTestAttach([])
          }
          setSampleRowOpen([])
          setDataIsLoading(false)

        });
      if (uoMs.length === 0) {
        UnitOfMeasureApi.getAll().then((uom) => {
          setUoMs(uom.sort((a, b) => a.uoMName.localeCompare(b.uoMName)));
        })
      }
    }
  }

  async function loadMethodResult(tests) {
    let methodNames = [];
      tests.forEach(async test => {
        if (!methodNames.find(m => m === test.methodFacility?.methodName) && test.methodFacility?.methodName) {
          methodNames.push(test.methodFacility?.methodName);
        }
      })

      if (methodNames.length > 0) {
        await MethodResultComponentApi.getByNames(methodNames).then((res) => {
          if (res.length > 0) {
            setListMethodResult(res)
          }
        });
      }
    }

  function loadMethodFacilityFilter() {

    MethodApi.getAll().then((res) => {
      if (res.length > 0) {
        let array = [];
        array.push({ id: undefined, name: "" });
        array.push(...res);

        setMethodList(array);
      }
    });
  }

  async function handleChangeMethod(methodName) {

    if (methodName) {
      setSearchCriteria(() => ({
        ...searchCriteria,
        methodName: methodName,
        facility: "",
        condition: ""
      }));

      setMethodFacilities(null);
      setConditionList(null);

      const method = methodList.find(f => f.name === methodName);
      let facilities = method?.methodFacilities;

      if (!method || !method?.methodFacilities || method?.methodFacilities?.length < 1) {
        facilities = await MethodFacilityApi.getFacilitiesByMethodName(methodName);
      }

      if ((facilities !== undefined && facilities !== null)) {
        let array = [];
        array.push({ testFacilityAbv: "" });
        facilities.forEach(item => {
          if (!array.find(i => i.testFacilityAbv === item.testFacilityAbv))
            array.push(item);
        })
        setMethodFacilities(array);
      }
    }
    else {
      setSearchCriteria(() => ({
        ...searchCriteria,
        methodName: "",
        facility: "",
        condition: ""
      }));
    }
  }

  function validateFields(field) {
    if (field) {
      setInvalidField({
        ...invalidField,
        [field]: searchCriteria[field] === "" || searchCriteria[field] === undefined ? getMessage('REQUIRED_FIELD') : "",
      });

      return (
        (searchCriteria[field] !== "")
      );

    }
    else {
      setInvalidField({
        ...invalidField,
        methodName: searchCriteria.methodName === "" || searchCriteria.methodName === undefined ? getMessage('REQUIRED_FIELD') : "",
        sampleName: searchCriteria.sampleName === "" || searchCriteria.sampleName === undefined ? getMessage('REQUIRED_FIELD') : "",
        facility: searchCriteria.facility === "" || searchCriteria.facility === undefined ? getMessage('REQUIRED_FIELD') : ""
      });

      return (
        (searchCriteria.methodName !== "" && searchCriteria.facility !== "")
        || (searchCriteria.sampleName !== "")
      );
    }
  }

  React.useMemo(() => {
    if (methodList === null) {
      loadMethodFacilityFilter();
    }
  }, [methodList])

  function handleChangeFacilities(selected) {
    if (selected) {
      setSearchCriteria((facility) => ({
        ...searchCriteria,
        facility: selected,
        condition: ""
      }));
      getConditions(selected);
    } else {
      setSearchCriteria((facility) => ({
        ...searchCriteria,
        facility: "",
        condition: ""
      }));
    }
  }

  function handleChangeOtherFilters(field, value) {
    if (value) {
      setSearchCriteria(() => ({
        ...searchCriteria,
        [field]: value
      }));
    } else {
      setSearchCriteria(() => ({
        ...searchCriteria,
        [field]: ""
      }));
    }
  }

  function getConditions(facility) {

    if (facility === undefined || facility === null || facility === "") {
      setConditionList(null);
    }
    else {
      let conditions = methodFacilities.filter(function (item) {
        return item.testFacilityAbv === facility
      });

      let conditionsResult = [];
      if (conditions.length > 0) {
        conditions.forEach(testCond => {
          testCond.testConditionOfferings?.forEach((condition) => {
            conditionsResult.push(condition.conditionOfferingName)
          });
        });

        if (conditionsResult.length > 0 && conditionsResult[0].length > 0)
          setConditionList(conditionsResult.sort((a, b) => a.localeCompare(b)));
        else
          setConditionList(null);
      }
    }
  }

  const determineStartArray = () => {
    return (listTest.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isTestRowOpen = (sampleName) => {
    if (testRowOpen) {
      if (testRowOpen.length > 0)
        return testRowOpen.indexOf(sampleName) !== -1;
    }

    return false;

  };

  const searchComponent = () => {

    switch (searchCategorySelected) {
      case 1:
        return <>
          <StyledBox>
            <Autocomplete
              size="small"
              noOptionsText={"Loading Methods..."}
              value={searchCriteria.methodName || null}
              autoHighlight
              autoSelect
              onChange={(e, value) => handleChangeMethod(value)}
              onBlur={e => validateFields("methodName")}
              disablePortal
              options={methodList ? methodList.map((method) => method.name.toString()) : []}
              sx={{ width: "15rem" }}
              renderInput={(params) => <TextField size="small" {...params} label="Method Name"
                error={invalidField.methodName.length === 0 ? false : true}
                helperText={invalidField.methodName} />}
            /> 
          </StyledBox>

          <StyledBox>
            {
              searchCriteria.methodName &&
                <Autocomplete
                  size="small"
                  autoHighlight
                  autoSelect
                  noOptionsText={"Loading Facilities..."}
                  value={searchCriteria.facility || null}
                  onChange={(e, value) =>
                    handleChangeFacilities(value)
                  }
                  disablePortal
                  options={methodFacilities != null ? methodFacilities.filter(facility => facility.methodStatusName === 'Active').map((method) => method.testFacilityAbv.toString()) : []}
                  sx={{ width: "10rem" }}
                  renderInput={(params) => <TextField size="small" {...params} label="Facility"
                    error={invalidField.facility.length === 0 ? false : true}
                    helperText={invalidField.facility}
                  />}
                />
            }

          </StyledBox>

          <StyledBox>
            {
              conditionList && searchCriteria.methodName ?
                <Autocomplete
                  size="small"
                  autoHighlight
                  autoSelect
                  noOptionsText={"Loading Conditions..."}
                  value={searchCriteria.condition || null}
                  onChange={(e, value) =>
                    handleChangeOtherFilters("condition", value)
                  }
                  disablePortal
                  options={conditionList}
                  sx={{ width: "15rem" }}
                  renderInput={(params) => <TextField size="small" {...params} label="Conditions"
                  />}
                /> : <></>
            }

          </StyledBox>
        </>
      case 2:
        return <StyledBox>
          <StyledTextField
            label="MIDAS Number"
            variant="outlined"
            size="small"
            value={searchCriteria.sampleName === null ? '' : searchCriteria.sampleName}
            error={invalidField.sampleName.length === 0 ? false : true}
            helperText={invalidField.sampleName}
            onBlur={e => validateFields("sampleName")}
            onChange={e =>
              handleChangeOtherFilters("sampleName", e.target.value)
            }
          />
        </StyledBox>
      default:
        return <></>
    }
  }

  function handleChangeSubTable(list, testID) {
    let newList = listTestResult.filter(t => t.testID !== testID);
    setListTestResult(() => ([
      ...newList,
      ...list
    ]));
  }

  function handleChangeTest(e, id, field) {
    let newList = listTest.map(test => {
      if (test.id === id) {
        return {
          ...test,
          [field]: e.target.value
        }
      } else {
        return test;
      }
    });
    setListTest([...newList]);
  }

  const handleTestRowClick = async (test) => {
    const id = test.id;
    const testRowOpenIndex = testRowOpen.indexOf(id);
    let newTestRowOpen = [];

    if (testRowOpenIndex === -1) {
      if (test.methodFacility?.methodName != null) {
        let methodResultComponents = listMethodResult.filter(m => m.methodName === test.methodFacility?.methodName);
        if (methodResultComponents.length === 0) {
          await MethodResultComponentApi.getByName(test.methodFacility?.methodName).then((res) => {
            if (res.length > 0) {
              if (uoMs.length === 0) {
                UnitOfMeasureApi.getAll().then((uom) => {
                  setUoMs(uom);
                })
              }
              methodResultComponents = res;
              setListMethodResult(res)
            }
          });
        }

        methodResultComponents.map((item, index) => {
          let pointTestResult = listTestResult.find(t => t.testID === test.id && item.resultComponentName === t.pointTestResult?.resultComponentName);
          if (!pointTestResult) {
            listTestResult.push({
              ...testResultInitialState,
              pointTestResult: {
                ...pointTestResultInitialState,
                resultComponentName: item.resultComponentName,
                uoMName: item.uoMName
              },
              resultDisplayOrder: index + 1,
              testID: id
            })
          }

          return {
            ...item,
            name: item.resultComponentName,
            testID: id,
          }
        })
        newTestRowOpen = newTestRowOpen.concat(testRowOpen, id);
      }
    } else if (testRowOpenIndex === 0) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen.slice(1));
    } else if (testRowOpenIndex === testRowOpen.length - 1) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen.slice(0, -1));
    } else if (testRowOpenIndex > 0) {
      newTestRowOpen = newTestRowOpen.concat(
        testRowOpen.slice(0, testRowOpenIndex),
        testRowOpen.slice(testRowOpenIndex + 1)
      );
    }
    setSampleRowOpen(newTestRowOpen);
  };

  function openModalSimpleButton(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleButton(true);
    setModalSimpleTitle(title);
    setModalSimpleText(text);
  }

  function closeModalSimpleButton() {
    setModalSimpleButton(false);
  }

  async function sendAttachs() {
    return new Promise((resolve, reject) => {
      if (listTestAttach.length > 0) {
        listTestAttach.forEach((item, index) => {
          const lastIndex = listTestAttach.length - 1;
          const files = item.files.filter(s => !s.testResultID);
          if (files.length > 0) {
           
            files.forEach((file, indexFile) => {
              const lastIndexFile = files.length - 1;
              const formData = new FormData();

                formData.append("file", file.file);
                formData.append("testId", item.testID);
                formData.append("isHighContent", file.isHighContent);

                TestResultApi.postAttachs(formData).then((res) => {
                  if (res.length < 1) {
                    setErrorSave(true)
                  }
    
                  if (lastIndex === index && lastIndexFile === indexFile) {
                    resolve();
                  }
                });
            })
          } else if (lastIndex === index) {
            resolve();
          }
        })
      } else {
        resolve();
      }
    })
  }

  async function createTestResults(listCreate) {
    if (listCreate.length > 0) {
      await TestResultApi.addTestResults(listCreate).then((res) => {
        if (res.length <= 0) {
          setErrorSave(true)
        }
      });
    }
  }

  async function updateTestResults(listUpdate) {
    if (listUpdate.length > 0) {
      await TestResultApi.updateTestResults(listUpdate).then((res) => {
        if (res.length <= 0) {
          setErrorSave(true)
        }
      });
    }
  }

  function completeTests(listCreate, listUpdate) {
    return new Promise((resolve, reject) => {
      let testIds = [];
      listCreate.forEach(item => {
        if (!testIds.find(i => i === item.testID)) {
          testIds.push(item.testID);
        }
      })
      listUpdate.forEach(item => {
        if (!testIds.find(i => i === item.testID)) {
          testIds.push(item.testID);
        }
      })

      listTestAttach.forEach((item) => {
        const files = item.files.filter(s => !s.testResultID);
        const withoutTestResult = !(listCreate.some(tr => tr.testID === item.testID) || listUpdate.some(tr => tr.testID === item.testID))
        if (files.length > 0 && !testIds.find(i => i === item.testID)) {
          testIds.push(item.testID)
        } else if (withoutTestResult && !testIds.find(i => i === item.testID)) {
          testIds.push(item.testID)
        }
      })

      if (testIds.length > 0) {
        testIds.forEach((id, index) => {
          const test = listTest.find(t => t.id === id);
          const lastIndex = testIds.length - 1;
          test.testStatusName = "Completed";
          let testResults = listTestResult.filter(t => t.testID === id)
          const hasAttach = listTestAttach.some(a => a.testID === id)
          if ((!testResults || testResults?.length === 0) && hasAttach) {
            testResults = listTestAttach.find(a => a.testID === id)?.files.map(item => {
              return {
                id: item.testResultID,
                testID: id,
                instrumentID: null,
                resultDisplayOrder: 0,
              }
            })
          }

          if (test && testResults && testResults.length > 0) {
            test.testResults = testResults.filter(f => (f.pointTestResult?.resultText && f.pointTestResult?.resultText !== '') || (f.pointTestResult?.resultValue && f.pointTestResult?.resultValue !== ''))
            TestApi.update(test).then((res) => {
              if (res.message !== 'Success')
              {
                setErrorSave(true)
                openModalSimpleButton('Fail', `Something went wrong updating the test, please try again later. ${res.message ? res.message : ""}`, 'Ok');
              }
              if (lastIndex === index) {
                resolve();
              }
            });
          } else {
            if (lastIndex === index) {
              resolve();
            }
          }
        })
      } else {
        resolve()
      }
    })
  }

  async function handleSave() {
    setErrorSave(false);
    let listTestIdIncomplete = []
    listTestResult.forEach(item => {
      if (!listTestIdIncomplete.find(t => t === item.testID)) {
        if (!item.isOptionalResult && (item.pointTestResult?.resultValue === null && item.pointTestResult?.resultText === "")) {
          listTestIdIncomplete.push(item.testID)
        }
      }
    })

    if (listTestIdIncomplete.length > 0) {
      openModalSimpleButton('Fail', 'There are required fields empty', 'Ok');
      setShowRequired(true);
      listTestIdIncomplete.forEach(item => {
        const open = isTestRowOpen(item);
        if (!open) {
          const test = listTest.find(t => t.id === item);

          if (test)
            handleTestRowClick(test)
        }
      });      
    }
    else {
      setIsLoadingSave(true);
      const listCreate = listTestResult.filter(i => i.id === 0 && !(i.pointTestResult?.resultValue === null && i.pointTestResult?.resultText === ""));
      const listUpdate = listTestResult.filter(i => i.id > 0);

      Promise.all([
        sendAttachs(),
        createTestResults(listCreate),
        updateTestResults(listUpdate)
      ]).then(async () => {
        if (errorSave)
          openModalSimpleButton('Fail', 'Something went wrong, please try again later', 'Ok');
        else {
          openModalSimpleButton('Success', 'Tests Saved with success', 'Ok');
        }

        await completeTests(listCreate, listUpdate);
        setIsLoadingSave(false);
        handleSearch();
        setShowRequired(false);
      })
    }
  }

  async function updateStatus(id, status) {
    setIsButton1Disabled(true);
    await TestApi.updateStatus([id], status).then(async (res) => {
      await handleSearch();
      setIsButton1Disabled(false);
      if (res.errors) {
        openModalSimpleButton('Fail', 'Something went wrong, please try again later', 'Ok');
      } else {
        openModalSimpleButton('Success', 'Tests Saved with success', 'Ok');
        handleCloseMoreOptions(null)
      }
    });
  }

  async function cancelTest(id) {
    setIsButton1Disabled(true);
    const test = listTest.find(t => t.id === id);
    if (!test.testComment || test.testComment === '') {
      openModalSimpleButton('Fail', 'Test comment is required', 'Ok');
    } else {
      await TestApi.cancelTests([id], test.testComment).then(async (res) => {
        await handleSearch();
        if (res.errors) {
          openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
        } else {
          openModalSimpleButton('Success', 'Tests Saved with success', 'Ok');
          handleCloseMoreOptions(null)
        }
      });
    }
    setIsButton1Disabled(false);
  }

  function handleFile(e, test) {
    const testAttach = listTestAttach.find(a => a.testID === test.id);
    if (testAttach) {
      const data = [];
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i];
        if (!testAttach.files.find(a => a.file.name === file.name)) {
          data.push({
            file: file,
            isHighContent: false
          });
        }
      }
      const attach = {
        ...testAttach,
        files: testAttach.files.concat(data)
      }

      let list = listTestAttach.filter(a => a.testID !== test.id)
      list = list.concat(attach)
      setListTestAttach(list)
    } else {
      const data = [];

      for (let i = 0; i < e.target.files.length; i++) {
        data.push({
          file: e.target.files[i], 
          isHighContent: false
        });
      }

      const attach = {
        testID: test.id,
        files: data
      }
      setListTestAttach(listTestAttach.concat(attach))
    }
  }

  const handleRemoveFile = (file, attachTest) => {
    const indexFile = attachTest.files.indexOf(file);
    const indexAttachTest = listTestAttach.indexOf(attachTest);

    if (indexFile >= 0 && indexAttachTest >= 0) {
      attachTest.files.splice(indexFile, 1);

      let list = listTestAttach.filter(a => a.testID !== attachTest.testID)
      list = list.concat(attachTest)
      setListTestAttach(list)
    }

  }

  const handleChangeFile = (item, test) => {
    const testsAttach = listTestAttach.map(testAttach => {
      if (testAttach.testID === test.id) {
        return {
          ...testAttach,
          files: testAttach.files.map(file => {
            if (item.file === file.file) {
              return {
                ...item,
                isHighContent: !item.isHighContent ? true : false
              }
            } else {
              return file
            }
          })
        }
        
      } else {
        return testAttach;
      }
    })

    setListTestAttach(testsAttach)

  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null)

    setAnchorEl(null)
  }

  function handleOpenMoreOptions(e, test) {
    setMoreOptionsSelected(test)

    setAnchorEl(e.currentTarget)
  }

  function closeModalTwoButtonsOpen() {
    setMessageSubmitter(null);
    setModalTwoButtonsOpen(false);
  }

  async function sendEmail(id) {
    if (messageSubmitter === null || messageSubmitter === '') {
      return;
    }
    setIsButton1Disabled(true);
    await TestApi.sendEmail({
      testId: id,
      message: messageSubmitter
    }).then((res) => {
      if (res.errors) {
        openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
      } else {
        openModalSimpleButton('Success', 'Email sent with success', 'Ok');
        closeModalTwoButtonsOpen()
        handleCloseMoreOptions();
      }
    });
    setIsButton1Disabled(false);
  }

  function downloadFileBlob(file) {
    if (file) {
      const a = document.createElement("a"); //Create <a>
      a.href = URL.createObjectURL(file);
      a.download = file.name; //File name Here
      a.click(); //Downloaded file
    }
  }

  return (

    <div>
      <Box>
        <StyledFormControl fullWidth>
          <StyledBox>
            <Autocomplete
              onChange={(e, value) => {
                const selected =  searchCategory.find(s => s.name === value)?.id
                if (selected) {
                  setListTest([]);
                  setSearchCriteria(() => ({ ...searchCriteriaInitialState }))
                  setSearchCategorySelected(() => (selected))
                  setInvalidField({
                    ...validationsInitialState
                  });
                }
              }}
              label="Search Category"
              size="small"
              value={searchCategory.length > 0 ? searchCategory.find(s => s.id === searchCategorySelected)?.name : ''}
              disablePortal
              options={searchCategory.map((search) => search.name)}
              sx={{ width: "35rem" }}
              renderInput={(params) => <TextField size="small" {...params} label="Search Category" />}
              autoSelect
              disableClearable
            />
          </StyledBox>
          {
            searchCategorySelected ?
              searchComponent()
              : <></>
          }

          {
            searchCategorySelected ?
              <>

                <StyledButton sx={{ width: "8rem" }}
                  variant='contained'
                  type="submit"
                  onClick={handleSearch}
                >Search</StyledButton>
              </>
              : <></>
          }

        </StyledFormControl>
        {
          <div style={{ marginTop: '2rem' }}>
            <UXDataTableWithoutBody
              tableWidth='100%'
              cols={simpleResultColHeaders}
              blankFirstHeader={true}
              tableId="simpleResultTable"
              rowLength={listTest.length}
              enablePaging={true}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              noDataFoundMessage={'There is no test data available'}
              enableAddIcon={false}
              addFunction={null}
              addToolTipText={null} 
              isDataLoading={dataIsLoading}             
            >

              <StyledTableBody key={"myTablebody"}>
                {determineStartArray().map((test, rowIndex) => {
                  let listResultByTest = listTestResult.filter(t => t.testID === test.id);
                  let attachByTest = listTestAttach.find(t => t.testID === test.id);
                  let countListMethodResult = listMethodResult.filter(t => t.methodName === test.methodFacility?.methodName).length;

                  return (
                    [
                      <TableRow
                        hover
                        key={`custom-row-${rowIndex}`}>
                        <StyledTableCell style={{width:'2rem'}}><IconButton aria-label="more options" onClick={(e) => handleOpenMoreOptions(e, test)}><MoreOptions color='black' /></IconButton></StyledTableCell>
                        <StyledTableCell style={{width:'3rem'}}>{test.age}</StyledTableCell>
                        <StyledTableCell>{test.methodFacility?.methodName}</StyledTableCell>
                        <StyledTableCell>{test.testConditionOffering?.conditionOfferingName}</StyledTableCell>
                        <StyledTableCell>{test.container?.sampleName ? formatMidasNumber(test.container?.sampleName) : ""}</StyledTableCell>
                        <StyledTableCell>{test.testEstimate}</StyledTableCell>
                        <StyledTableCell>{test.container?.containerNumber}</StyledTableCell>
                        <StyledTableCell>{test.testSequenceNumber}</StyledTableCell>
                        <StyledTableCell>{countListMethodResult}
                        {countListMethodResult > 0 &&
                          <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => handleTestRowClick(test)}>
                            {isTestRowOpen(test.id) ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        }
                        </StyledTableCell>
                        <StyledTableCell>
                          <StyledChildInput

                            size="small"
                            value={test.testComment === null ? '' : test.testComment}
                            onChange={e => {
                              handleChangeTest(e, test.id, 'testComment');
                            }}
                            variant="outlined"
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <StyledContainerAttach>
                            <div>
                              {attachByTest && attachByTest.files && attachByTest.files.length > 0 && `${attachByTest.files.length} ${attachByTest.files.length > 1 ? 'files' : 'file'}`}
                            </div>
                            <label htmlFor={`${test.id}-input-file`}>
                              <input accept="image/*" id={`${test.id}-input-file`} type="file" onChange={(e) => handleFile(e, test)} multiple hidden />
                              <IconButton aria-label="upload" component="span">
                                <AttachFile color='black' />
                              </IconButton>
                            </label>
                          </StyledContainerAttach>
                        </StyledTableCell>

                      </TableRow>,
                      <UXDataTableNested key={`data-table-nested-${rowIndex}`} tableWidth={'100%'} cols={subColHeaders} blankFirstHeader={false} colSpan={10} isOpenComparator={test.id} isOpenArray={testRowOpen}>
                        <StyledTableBody key={`sub-table-body-${rowIndex}`}>
                          {listResultByTest && listResultByTest.length > 0 ? listResultByTest.map((testResult, rowIndex) => {
                            const methodResult = listMethodResult.find(m => m.methodName === test.methodFacility?.methodName && m.resultComponentName === testResult.pointTestResult?.resultComponentName);
                            testResult.isOptionalResult = methodResult?.isOptionalResult;

                            return (

                              <TableRow key={rowIndex + 'sub'}>
                                <StyledTableCell>
                                  {
                                    testResult.pointTestResult?.resultComponentName
                                  }
                                </StyledTableCell>
                                <StyledTableCell>
                                  {
                                    testResult.isOptionalResult ? "Optional" : "Required"
                                  }
                                </StyledTableCell>
                                {testResult.pointTestResult &&
                                  <>
                                    <StyledTableCell>
                                      <StyledChildInput
                                        size="small"
                                        value={testResult.pointTestResult?.resultValue != null ? testResult.pointTestResult?.resultValue : testResult.pointTestResult?.resultText}
                                        onChange={e => {
                                          if (isNumber(e.target.value)) {
                                            testResult.pointTestResult.resultValue = e.target.value;
                                            testResult.pointTestResult.resultText = "";
                                            handleChangeSubTable(listResultByTest, test.id);
                                          } else {
                                            testResult.pointTestResult.resultText = e.target.value;
                                            testResult.pointTestResult.resultValue = null;
                                            handleChangeSubTable(listResultByTest, test.id);
                                          }
                                        }}
                                        label="Value"
                                        error={!testResult.isOptionalResult && showRequired}
                                      >
                                      </StyledChildInput>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Autocomplete
                                        size="small"
                                        noOptionsText={"Loading UoMs..."}
                                        value={uoMs.length > 0 ? uoMs.find(u => u.uoMName === testResult.pointTestResult?.uoMName)?.uoMName : ''}
                                        onChange={(e, value) => {
                                          testResult.pointTestResult.uoMName = value;
                                          handleChangeSubTable(listResultByTest, test.id);
                                        }}
                                        disablePortal
                                        options={uoMs.map((uom) => uom.uoMName)}
                                        sx={{ width: "10rem" }}
                                        renderInput={(params) => <TextField size="small" {...params} label="UoM" />}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>

                                      <StyledChildInput
                                        size="small"
                                        value={testResult.pointTestResult?.resultComment}
                                        onChange={e => {
                                          testResult.pointTestResult.resultComment = e.target.value;
                                          handleChangeSubTable(listResultByTest, test.id);
                                        }}
                                        label="Result Remark"
                                        variant="outlined"
                                      >
                                      </StyledChildInput>
                                    </StyledTableCell>
                                  </>
                                }
                              </TableRow>

                            )
                          }) : (<TableRow>
                            <StyledTableCell colSpan='10'>
                              <p>No Methods Found</p>
                            </StyledTableCell>
                          </TableRow>)}
                        </StyledTableBody>
                        {attachByTest && attachByTest.files && attachByTest.files.length > 0 && <UXDataTableWithoutBody
                            tableWidth='100%'
                            cols={['File Name', 'Is High Content']}
                            blankFirstHeader={true}
                            tableId={`midasFileAttachments`}
                            rowLength={attachByTest.files.length}
                            enablePaging={false}
                            rowsPerPage={attachByTest.files.length}
                            page={0}
                            handleChangePage={null}
                            handleChangeRowsPerPage={null}
                            noDataFoundMessage={'No Files Added'}
                            enableAddIcon={false}
                            addFunction={() => null}
                            addToolTipText=""
                            isDataLoading={false}
                        >
                            <StyledTableBody key={"myTablebody"}>
                                {attachByTest.files.map((file, rowIndex) => {
                                    return (<TableRow
                                        hover
                                        key={`custom-row-${rowIndex}`}>
                                        <StyledTableCell component='th' scope='row'>
                                            <IconButton aria-label="upload" component="span" onClick={() => handleRemoveFile(file, attachByTest)} disabled={file?.file?.testResultID}>
                                              <Cancel color='black' />
                                            </IconButton>
                                            <Tooltip title="Download File" placement="right">
                                              <IconButton aria-label="download" component="span" onClick={() => ((file?.file?.filePath || file?.file?.storagePath) ? 
                                                  downloadFile('midasanalyticalfiles', file?.file?.fileName,  file?.file?.filePath ?? file?.file?.storagePath, openModalSimpleButton) : 
                                                  downloadFileBlob(file?.file))}>
                                                <DownloadIcon/>
                                              </IconButton>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell>{file.file.name}</StyledTableCell>
                                        <StyledTableCell>
                                          <FormControlLabel control={<Checkbox checked={file.isHighContent}
                                              onChange={(e) => {
                                                handleChangeFile(file, test)
                                              }}
                                              disabled={file?.file?.testResultID}
                                          ></Checkbox>} label={file.isHighContent ? "Yes" : "No"} />
                                        </StyledTableCell>
                                    </TableRow>
                                    );
                                })}
                            </StyledTableBody>
                        </UXDataTableWithoutBody>
                        }
                      </UXDataTableNested>
                    ]
                  );
                })}
              </StyledTableBody>
            </UXDataTableWithoutBody>
            {listTest.length > 0 &&
              <div style={{ display: "flex" }}>

                <StyledBox sx={{ mb: 1, mt: 2, justifyContent: "space-between" }}>
                  <StyledButton sx={{ width: "10rem" }}
                    variant='contained'
                    type="submit"
                    onClick={e => handleSave(e)}
                    disabled={isLoadingSave}
                  >Complete Tests</StyledButton>
                  {isLoadingSave && <CircularProgress style={{textTransform: "none", marginLeft:"15px"}}></CircularProgress>}
                </StyledBox>
              </div>
            }
          </div>
        }
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={anchorEl && moreOptionsSelected ? true : false}
          onClose={() => handleCloseMoreOptions(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => cancelTest(moreOptionsSelected.id)} disabled={isButton1Disabled}>Cancel Test</MenuItem>
          <MenuItem onClick={() => updateStatus(moreOptionsSelected.id, "InProgress")} disabled={moreOptionsSelected?.testStatusName === "InProgress" || isButton1Disabled}>Set To in Progress</MenuItem>
          <MenuItem onClick={() => setModalTwoButtonsOpen(true)}>Notify Submitter</MenuItem>
        </Menu>
      </Box>
      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
        <label>
          {modalSimpleText}
        </label>
      </ModalSimpleButton>

      <ModalTwoButtons title={'Please provide a message and e-mail for send.'} button2Text={'Back'} button2Action={closeModalTwoButtonsOpen} isButton1Disabled={isButton1Disabled} button1Text={'Send'} button1Action={() => sendEmail(moreOptionsSelected.id)} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen} setClose={closeModalTwoButtonsOpen}>
        <StyledBodyModalEmail style={{minWidth: '450px'}}>
          <TextField
            multiline={true}
            onChange={e => setMessageSubmitter(e.target.value)}
            label="Message"
            size="small"
            minRows={4}
            required={true}
            value={messageSubmitter === null ? '' : messageSubmitter} />
        </StyledBodyModalEmail>
      </ModalTwoButtons>

    </div>
  );
};

export default SimpleResultEntry;
