import { React, useState, useEffect } from "react";
import { Box, FormControlLabel, Checkbox, Typography, styled, Modal, TableRow, Paper, TableContainer, Table, TableHead, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {RichTreeView} from '@mui/x-tree-view/RichTreeView';
import { StyledTableBody, StyledTableCell12pt } from "../../../components/UXDataTable";
import { isNumber, RoundNumber, formatMidasNumber} from "../../../global";


//import TreeViewBaseItem  from '@mui/x-tree-view/models';
const helperLabelNumber = "Invalid"
const StyledModalBox = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
    backgroundClip: 'padding-box',
}));

const StyledModalHeader = styled('div')({
    marginLeft: '15px',
    marginTop:"20px",
    display: 'flex',
    justifyContent: 'left'
});

const StyledModalBody = styled('div')({
    margin: '1rem',
    width:"100%",
    height:"100%"
});

const BulkEditComponents = ({ open, setOpen, setClose, myStudy, isUserDisabled, errorCheck, updateData_BlendComponent, updateErrorChecks_BlendComponent, EvaluateLowerAmountPercentages }) => {

    const handleClose = () => setClose ? setClose() : setOpen(false);
    const [selectedBlends, setSelectedBlends] = useState([]);
    const [treeStructure, setTreeStructure] = useState([])
    const usedComponents = filteredUniqueComponents();
 
    function CheckMasterCheckBoxStatus() {

       if (selectedBlends.length > 0 && selectedBlends.length < myStudy.blendRequests.length)
       {
           return false
       } else if (selectedBlends.length === myStudy.blendRequests.length)
       {
           return true
       }
    }

    useEffect(() => {
        let treeView = []

        myStudy.blendRequests.forEach((oBlendRequest, index) => {
            treeView.push({id:oBlendRequest.blendName, label:`Blend ${oBlendRequest.blendName}`})
        });

        setTreeStructure(treeView)

    }, [myStudy.blendRequests])

    const handleSelectedItemsChange = (event, ids) => {
        setSelectedBlends(ids);
    };
    
    const handleSelectClick = (event) => {
        if (event.target.checked)
        {
            setSelectedBlends(getAllItemItemIds())
        } else {
            setSelectedBlends([])
        }

    };

    const getAllItemItemIds = () => {
        const ids = [];
        const registerItemId = (item) => {
            ids.push(item.id);
            item.children?.forEach(registerItemId);
        };
        
        treeStructure.forEach(registerItemId);
    
        return ids;
    };

    function filterSelectedBlends () {
        const copyStudy = structuredClone(myStudy)

        return copyStudy.blendRequests.filter(blend => selectedBlends.includes(blend.blendName)).sort((a, b) => a.blendName.length - b.blendName.length || a.blendName.localeCompare(b.blendName));
        //myBlendStudy.BlendRequests = myBlendStudy.BlendRequests.OrderBy(m => m.BlendName.Length).ThenBy(m => m.BlendName).ToList();
    };

    function filteredUniqueComponents () {
        let uniqueComponents = []

        filterSelectedBlends().forEach(oBlend => {
            oBlend.blendComponents.forEach(oComponent => {
                //  if (uniqueComponents.filter(obj => (obj.substance ? obj.substance.chemID : obj.substanceName) === (oComponent.substance ? oComponent.substance.chemID : oComponent.substanceName)).length === 0)
                if (oComponent.substance && uniqueComponents.filter(obj => (obj.substance?.chemID  ===  oComponent.substance?.chemID)).length === 0)
                {
                    uniqueComponents.push(oComponent)
                }
            })
        });

        //sort alphabetically
        //return uniqueComponents.sort((a, b) => a.substanceName.localeCompare(b.substanceName));

        //sorts from used in most recent blend to oldest blend
        //return uniqueComponents.reverse();

        return uniqueComponents;

    };

    function SumOfBlendTargets (oBlend) {

        let sum = 0;
    
        for (var iComponent = 0; iComponent <  oBlend.blendComponents.length; iComponent++) 
        {
            if (isNumber(oBlend.blendComponents[iComponent].targetAmountLower) && oBlend.blendComponents[iComponent].componentUoM 
            && (oBlend.blendComponents[iComponent].componentUoM.uoMName === 'wt%' || oBlend.blendComponents[iComponent].componentUoM.uoMName === 'vol%'))
            {
                sum = sum + Number(oBlend.blendComponents[iComponent].targetAmountLower)
            }
        }
    
        return RoundNumber(sum, 2);
    };

    // function DetermineUoMDisplay (blend, component) {
    //     const blendUoM = (blend.requestUoM ? blend.requestUoM.uoMName : blend.requestUoMName)
    //     const componentUoM = (component.componentUoM ? component.componentUoM.uoMName : component.componentUoMName)

    //     if (blendUoM !== componentUoM)
    //     {
    //         return componentUoM
    //     } else {
    //         return ""
    //     }
    // };
    
    return (
        <>
            <Modal 
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-header"
                aria-describedby="modal-modal-body"
            >
                <StyledModalBox style={{width:"95%", height:"95%", overflow:"hidden"}}>
                        <StyledModalHeader id="modal-modal-header" >
                            <Typography variant="h6" component="h6">Bulk Edit Components</Typography>
                                <Box display="flex" alignItems={"center"} marginLeft="auto" marginRight="20px">
                                    <CloseIcon onClick={() => setOpen(false)}></CloseIcon>
                                </Box>
                        </StyledModalHeader>
                    <StyledModalBody id="modal-modal-body">


                    <label>
                        {'You can select which blends/batches you want or view them all at once.'}
                    </label>


                    <div style={{
                        display: "flex",
                        marginTop:"20px",
                        height:"80%",
                        width:"95%",
                        fontFamily: "EMprint",
                        fontWeight: "900",
                        fontSize: "10px",
                    }}>
                
                    <Box sx={{ border:1, width:"15%", overflow:"auto"}}>
                        <div>
                            <FormControlLabel   
                                label={
                                    <Typography
                                    sx={{
                                        fontFamily:"EMprint",
                                        fontWeight:"600",
                                }}>All Blends</Typography>
                                }
                                labelPlacement="start"
                                control={
                                <Checkbox
                                    checked={CheckMasterCheckBoxStatus()}
                                    indeterminate={selectedBlends.length > 0 && selectedBlends.length < myStudy.blendRequests.length}
                                    onChange={(event) => handleSelectClick(event)}
                                />}
                            />

                            <Box>      
                                <RichTreeView
                                    items={treeStructure}
                                    selectedItems={selectedBlends}
                                    onSelectedItemsChange={handleSelectedItemsChange}
                                    multiSelect
                                    checkboxSelection
                                />
                            </Box>
                        </div>
                    </Box>

                    <Box sx={{ border: 1, marginLeft: "20px", width:"85%", overflow:"auto"}}>
                        <div style={{display:"flex", paddingLeft:"10px"}}>
                            <Box sx={{ width: '40%'}}>
                                <Paper sx={{ width: '100%', mb: 2 }}>
                                    <TableContainer>
                                        <Table size='small' aria-label='customized table' id={'componentTable'}>
                                            <TableHead key="table header" style={{ backgroundColor: 'white' }}>
                                                <TableRow sx={{height:"111px"}}>
                                                    <StyledTableCell12pt>{'Chem ID'}</StyledTableCell12pt>
                                                    <StyledTableCell12pt>{'Substance Name'}</StyledTableCell12pt>
                                                </TableRow>
                                            </TableHead>

                                            <StyledTableBody style={{fontSize:10}}>
                                                {usedComponents.map((oComponent, index) => {
                                                    return (
                                                        oComponent.substance &&
                                                            <TableRow sx={{height:"55px"}} key={`ChemIDs_${oComponent.substance.chemID}`}>
                                                                <StyledTableCell12pt  sx={{width: '200px'}} style={{ whiteSpace: 'nowrap', fontSize:12}}>
                                                                    {oComponent.substance.chemID}
                                                                </StyledTableCell12pt>
                                                                <StyledTableCell12pt sx={{width: '400px'}} style={{ whiteSpace: 'nowrap'}}>
                                                                    {oComponent.substance.substanceName}
                                                                </StyledTableCell12pt>
                                                            </TableRow>
                                                    )})
                                                }
                                            </StyledTableBody>

                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
{/* 
                            <Box sx={{ width: '70%' }}> */}
                            <Box sx={{ maxWidth: '60%'}}>
                                <Paper sx={{ width: '100%', mb: 2 }}>
                                    <TableContainer sx={{ width: '100%', maxHeight: 'unset'}} >
                                        <Table sx={{ minWidth: 50 }} size='small' aria-label='customized table' id={'componentTable'} stickyHeader={false}>
                                            <TableHead key="table header" style={{ backgroundColor: 'white'}}>

                                            <TableRow key={`blendNameRow`}>
                                                {filterSelectedBlends().map((oBlend, index) => {
                                                    const largestBatch = oBlend.batches.reduce(
                                                        (prev, current) => {
                                                            return prev.batchSequenceNumber > current.batchSequenceNumber ? prev : current
                                                        }
                                                    );

                                                    return (
                                                            <StyledTableCell12pt key={`blendNameRowCell ${oBlend.blendName}`} sx={{width: '125px'}}>{`${oBlend.blendName}/${largestBatch.batchSequenceNumber}`}</StyledTableCell12pt>
                                                    )
                                                })
                                                }
                                            </TableRow>
                                            
                                            <TableRow key={`batchStateRow`}>
                                                {filterSelectedBlends().map((oBlend, index) => {
                                                    const largestBatch = oBlend.batches.reduce(
                                                        (prev, current) => {
                                                            return prev.batchSequenceNumber > current.batchSequenceNumber ? prev : current
                                                        }
                                                    );

                                                    return (
                                                            <StyledTableCell12pt key={`batchStateRowCell ${oBlend.blendName}`} style={{ whiteSpace: 'nowrap'}}>{largestBatch.preparedSampleName ? formatMidasNumber(largestBatch.preparedSampleName) : largestBatch.batchStatusName}</StyledTableCell12pt>
                                                    )
                                                })
                                                }
                                            </TableRow>

                                            
                                            <TableRow key={`targetBlendRow`}>
                                                {filterSelectedBlends().map((oBlend, index) => {
                                                    return (
                                                            <StyledTableCell12pt key={`targetBlendRowCell ${oBlend.blendName}`}>{`${SumOfBlendTargets(oBlend)}%`}</StyledTableCell12pt>
                                                    )
                                                })
                                                }
                                            </TableRow>

                                            </TableHead>

                                            <StyledTableBody key={`blendValuesBody`}>
                                                {usedComponents.map((oMasterComponent, i) => (
                                                    <TableRow sx={{height:"55px"}} key={`componentInfo${oMasterComponent.substance?.chemID}`}>
                                                        {filterSelectedBlends().map((oBlend, blendIndex) => {

                                                            //const oMatchingComponent = oBlend.blendComponents.find(c => (c.substance ? c.substance.chemID : c.substanceName) === (oMasterComponent.substance ? oMasterComponent.substance.chemID : oMasterComponent.substanceName))
                                                            //const blendComponentIndex = oBlend.blendComponents.findIndex(c => (c.substance ? c.substance.chemID : c.substanceName) === (oMasterComponent.substance ? oMasterComponent.substance.chemID : oMasterComponent.substanceName))
                                                            const oMatchingComponent = oBlend.blendComponents.find(c => (c.substance?.chemID) === (oMasterComponent.substance?.chemID))
                                                            const blendComponentIndex = oBlend.blendComponents.findIndex(c => (c.substance?.chemID) === (oMasterComponent.substance?.chemID))
                                                            const blendRequestIndex = myStudy.blendRequests.findIndex(c => c.blendName === oBlend.blendName)
                                                            const blendErrorObj = errorCheck.blendRequests.find(obj => obj._blendLetter === oBlend.blendName)
                                                            const masterDisableComponents = (oBlend.blendStatusName !== 'Submitted' 
                                                                                            && oBlend.blendStatusName !== 'Needs Review' 
                                                                                            && oBlend.blendStatusName !== 'Draft' 
                                                                                            && oBlend.blendStatusName !== null) || isUserDisabled

                                                            return (            
                                                                oMatchingComponent ?  
                                                                (
                                                                    <StyledTableCell12pt style={{ whiteSpace: 'nowrap'}}>
                                                                        <TextField style={{width:"75px"}}
                                                                            disabled = {masterDisableComponents || oMatchingComponent.isBalance || oBlend.requestUoM === null}
                                                                            size="small"
                                                                            margin="none" 
                                                                            variant="outlined"
                                                                            inputProps={{ style: { fontSize: 11} }}
                                                                            InputLabelProps={{shrink: true}}
                                                                            value = {oMatchingComponent.targetAmountLower === null ? '' : oMatchingComponent.targetAmountLower}
                                                                            onChange = {e => {updateData_BlendComponent('targetAmountLower', e.target.value, blendComponentIndex, blendRequestIndex)}}
                                                                            error = {blendErrorObj.blendComponents[blendComponentIndex].targetAmountLower === null ? false : blendErrorObj.blendComponents[blendComponentIndex].targetAmountLower}
                                                                            onBlur={e => {updateErrorChecks_BlendComponent('targetAmountLower', EvaluateLowerAmountPercentages(oMatchingComponent, myStudy), blendComponentIndex, blendRequestIndex)}}
                                                                            helperText = {blendErrorObj.blendComponents[blendComponentIndex].targetAmountLower ? helperLabelNumber : ''}
                                                                        />
                                                                        {/* <label style={{marginLeft:".5rem", alignItems:"flex-end"}}>{DetermineUoMDisplay(oBlend, oMatchingComponent)}</label> */}
                                                                    </StyledTableCell12pt>
                                                                ) : (
                                                                    <StyledTableCell12pt>
                                                                    </StyledTableCell12pt>
                                                                )
                                                            )
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </StyledTableBody>

                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </div>
                    </Box>
                    </div>

                </StyledModalBody>
            </StyledModalBox>
            </Modal>
        </>
    );
};

export default BulkEditComponents;