import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {
  ConvertUOMs,
  formatMidasNumber,
  globalMessages,
  GetRequiredSampleSize,
  GetRequiredSampleSizeUoM,
  BuildTestingPayload
} from "../../../global";
import ModalMessages from "../../../components/Modal/ModalSimpleButton";
import Container from "../../../api/LIMS/Container";
import TrashIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import UserContext from "../../../context/UserContext";
import { IconButton, TableCell, TextField, Tooltip } from "@mui/material";
import MethodSelection from "../../../components/MethodSelection";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import LocateContainer from "../../Tests/NewTests/LocateContainer";
import { GlobalButton, Option, StyledAutocomplete } from "../../styles";
import {
  StyledDiv,
  sampleGridFontSize,
  subsectionTitle,
} from "../NewWorkRequests/constants";
import { isFieldRequired } from "../requirement-config";
import { getConvertedCurrentAmount } from "../shared";
import { useRef, useState, useContext } from "react";

const MySampleInfoCols = [
  "MIDAS #",
  "Container",
  "UoM",
  "Amt. Available",
  "Description",
  "PSIMSID",
  'Test Return Location',
];

const MySampleInfoColsQtySelection = [
  "MIDAS #",
  "Method / Facility",
  "UoM",
  "Minimum Needed",
  "Amt. Requested",
];
export const CONTAINERING_METHOD_ENUM = {
  MIDAS: "midas",
  NON_SPLIT: "nonsplit",
};

const RECOMMENDATIONS_ERROR_MESSAGES = {
  NO_CONTAINERS: 'No containers found. Please hit "Get Recommendations" button',
  INVALID_AMOUNT:
    "Invalid amount available. Container does not have enough volume for all tests.",
  REASK_RECOMMENDATIONS:
    "Container and/or Methods has been changed. Please get new recommendations.",
};

export default function ContainerRegistrationWithTest({
  myWorkRequest,
  setMyWorkRequest,
  DeleteSampleRow,
  updateData_WorkRequestSample,
  testJson,
  setTestJson,
  tempHasErrors,
  setTempHasErrors,
  locateContainerIsOpen,
  locateContainerMidas,
  setLocateContainerIsOpen,
  unitOfMeasureLoading,
  unitOfMeasure,
  AddNewSampleRow,
  isProcessing,
  containerRecommendation,
  setContainerRecommendation,
  containerRecommendationErrorCheck,
  setContainerRecommendationErrorCheck,
  canEdit,
  locations,
  isCancelledOrCompleted,
}) {
  const currentUser = useContext(UserContext);
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = "Ok";
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [needsRecommendationsAgain, setNeedsRecommendationsAgain] =
    useState(true);
  const [recommendationMessage, setRecommendationMessage] = useState(
    RECOMMENDATIONS_ERROR_MESSAGES.NO_CONTAINERS
  );
  const recommendationRef = useRef(
    isProcessing ? myWorkRequest.workRequestSamples : []
  );
  if (
    containerRecommendation?.length > 0 &&
    recommendationRef.current.length > 0
  ) {
    setContainerRecommendationErrorCheck(
      containerRecommendation.some(
        (x, i) =>
          x.requestedAmount < recommendationRef.current[i].requestedAmount
      ) || needsRecommendationsAgain
    );
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  async function handleGetRecommendation() {
    const errosArray = checkAmountAvailable(
      testJson.methods,
      myWorkRequest.workRequestSamples
    );
    if (errosArray.length > 0) {
      openModalMessages(
        "Container Amount Error",
        errosArray.map((x) => <div>{x}</div>)
      );
      setRecommendationMessage(RECOMMENDATIONS_ERROR_MESSAGES.INVALID_AMOUNT);
      // return;
    }

    let newTests = []
    if (testJson.methods.length > 0)
    {
      for (const oMethodInfo of testJson.methods)
      {
        const tests = await BuildTestingPayload(oMethodInfo, null, null, myWorkRequest.chargeCode, currentUser.username)

        if (tests === null || typeof tests === 'string' || typeof tests[0] === 'string')
        {
          if (Array.isArray(tests))
          {
            openModalMessages("Error Building Tests", tests)        
            return
          } else {
            openModalMessages("Error Building Tests", `There was an error building the test payload. ${tests ? tests : ''}`)        
            return
          }
        }
        for (const oTest of tests)
        {
          newTests.push(oTest)  
        }
      }
    }

    setNeedsRecommendationsAgain(errosArray.length > 0);
    const payload = {
      containeringMethod: testJson.containeringMethod,
      requireParentContainer: false,
      tests: newTests,
    };
    Container.GetContainersToCreate(payload)
      .then((result) => {

        if (result.message === 'Success') {
          let newWorkRequestSampleList = [];
          let newWorkRequest = structuredClone(myWorkRequest);
          let idsUsed = [];
          result.result.forEach((suggestedContainer) => {
            delete suggestedContainer.containerType.defaultContainerUoM;

            newWorkRequest.workRequestSamples.forEach((sample, index) => {
              newWorkRequestSampleList.push({
                ...sample,
                id: idsUsed.includes(sample.id) ? 0 : sample.id,
                requestedAmount: suggestedContainer.currentAmount,
                requestedAmountUoM: suggestedContainer.containerSizeUoM,
                unitOfMeasure: suggestedContainer.uom,
                newContainer: {
                  ...suggestedContainer,
                  tests: suggestedContainer.tests,
                  containerStatusName: "Confirmed",
                  containerStatus: null,
                  parentContainer: sample.existingContainer ?? null,
                  containerNumber: 0,
                  locationName: sample.requestedLocationName,
                  location: sample.location,
                  subLocation: sample.deliveryLocationSublocation,
                  createdDate: null,
                  ownerEmail: newWorkRequest.requestedByEmail,
                  currentAmount: sample.requestedAmount ?? suggestedContainer.currentAmount,
                },
              });
              idsUsed.push(sample.id);
            });
          });
          recommendationRef.current = newWorkRequestSampleList;
          setContainerRecommendation(newWorkRequestSampleList);

        } else {
          openModalMessages("Error Getting Containers", `There was an error retrieving containers to create. ${result.message ? result.message : ''}`)
        }

      })
  }

  function checkAmountAvailable(methods, workRequestSamples) {
    let containerAmtError = [];

    workRequestSamples.forEach((sample, index) => {
      const currentContainer = sample.sample.containers.find(
        (x) => x.id === sample.existingContainerID
      );
      const amtNeededForTest = methods.reduce((acc, currentMethod) => {
        let convertedValue = ConvertUOMs(
          sample.sample,
          sample.sample.substance,
          GetRequiredSampleSize(currentMethod),
          unitOfMeasure.find(
            (x) =>
              x.uoMName === GetRequiredSampleSizeUoM(currentMethod)
          ),
          currentContainer?.uom ?? null
        );
        return acc + convertedValue;
      }, 0);

      if (currentContainer?.currentAmount < amtNeededForTest) {
        containerAmtError.push(
          `Container ${currentContainer.containerNumber
          } of Sample ${formatMidasNumber(
            sample.sample.sampleName
          )} does not have enough volume for all tests. Amount needed: ${amtNeededForTest.toFixed(
            2
          )} ${currentContainer.containerSizeUoM
          } - Container amount: ${currentContainer.currentAmount.toFixed(2)} ${currentContainer.containerSizeUoM
          }`
        );
      }
    });
    return containerAmtError;
  }

  function updateData_ContainerRecommendation(property, value, index) {
    if (property === "requestedAmountUoM") {
      const newContainerRecommendation = containerRecommendation.map(
        (sample, idx) => {
          if (index === idx) {
            return {
              ...sample,
              requestedAmountUoM: value.uoMName,
              unitOfMeasure: value,
              requestedAmount: ConvertUOMs(
                sample.sample,
                sample.sample.substance,
                sample.requestedAmount,
                sample.unitOfMeasure,
                value
              ),
            };
          }
          return sample;
        }
      );

      setContainerRecommendation(newContainerRecommendation);
    } else {
      const newContainerRecommendation = containerRecommendation.map(
        (sample, idx) => {
          if (index === idx) {
            return {
              ...sample,
              [property]: value,
            };
          }
          return sample;
        }
      );
      setContainerRecommendation(newContainerRecommendation);
    }
  }

  function filterContainerRecommendation(methods) {
    setContainerRecommendation(containerRecommendation.filter(f =>
      methods.some(s =>
        f.newContainer?.tests?.some(ct => ct.methodFacility.methodName === s.methodFacility.methodName &&
          ct.methodFacility.testFacilityAbv === s.methodFacility.testFacilityAbv)
      )
    ));
  }

  function toFixedFourDecimals(number) {
    return Math.round(number * 1e4) / 1e4;
  }

  return (
    <Box width={"100%"}>
      <Box style={{ marginTop: "1rem" }}>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={MySampleInfoCols}
          blankFirstHeader={true}
          tableId="midasNumbersTable"
          rowLength={myWorkRequest.workRequestSamples.length}
          enablePaging={false}
          rowsPerPage={0}
          page={0}
          handleChangePage={null}
          handleChangeRowsPerPage={null}
          noDataFoundMessage="No MIDAS Numbers Found"
          enableAddIcon={canEdit}
          addFunction={() => {
            setNeedsRecommendationsAgain(true);
            setRecommendationMessage(
              RECOMMENDATIONS_ERROR_MESSAGES.REASK_RECOMMENDATIONS
            );
            AddNewSampleRow();
          }}
          addToolTipText="Add new sample"
          isDataLoading={false}
        >
          <StyledTableBody>
            {myWorkRequest.workRequestSamples.map(
              (oWorkRequestSample, index) => {
                const totalAmt = getConvertedCurrentAmount(
                  oWorkRequestSample,
                  false
                );
                return (
                  <StyledTableRow key={`WRSamplesTable~Row${index}`}>
                    <StyledTableCell component="th" scope="row">
                      {index > 0 && !myWorkRequest.id && (
                        <Tooltip title="Delete Sample" placement="right">
                          <TrashIcon
                            onClick={() => DeleteSampleRow(index)}
                          ></TrashIcon>
                        </Tooltip>
                      )}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <ValidatedMidasNumberTextField
                        isDisabled={isProcessing || !canEdit}
                        margin="none"
                        showLabel={true}
                        fontSize={12}
                        fieldWidth="150px"
                        midasNumberObject={oWorkRequestSample.sample}
                        setMidasNumberObject={(e) => {
                          if (e && (!myWorkRequest.id || myWorkRequest.id < 1)) {
                            updateData_WorkRequestSample("sample", e, index, {
                              "returnLocationName": "DISCARD"
                            });
                          } else {
                            updateData_WorkRequestSample("sample", e, index);
                          }
                        }}
                        hasErrors={tempHasErrors}
                        setHasErrors={setTempHasErrors}
                        chemIDToMatch={null}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <StyledAutocomplete
                            renderOption={(props2, option) => (
                              <Option {...props2}>
                                {option.containerNumber}
                              </Option>
                            )}
                            disabled={
                              oWorkRequestSample.sample === null || isProcessing || !canEdit
                            }
                            disablePortal
                            noOptionsText={"No Containers Found"}
                            options={
                              oWorkRequestSample?.sample?.containers.filter(x => x.containerStatusName === 'Confirmed' || x.id === oWorkRequestSample?.existingContainerID) ?? []
                            }
                            onChange={(e, value) => {
                              setNeedsRecommendationsAgain(true);
                              setRecommendationMessage(
                                !containerRecommendation?.length
                                  ? RECOMMENDATIONS_ERROR_MESSAGES.NO_CONTAINERS
                                  : RECOMMENDATIONS_ERROR_MESSAGES.REASK_RECOMMENDATIONS
                              );

                              updateData_WorkRequestSample(
                                "existingContainerID",
                                value?.id,
                                index
                              );
                            }}
                            value={oWorkRequestSample?.existingContainerID}
                            getOptionLabel={(option) => {
                              return option?.containerNumber
                                ? option.containerNumber.toString()
                                : oWorkRequestSample?.sample?.containers
                                  .length > 0
                                  ? oWorkRequestSample?.sample?.containers
                                    .find((f) => f.id === option)
                                    ?.containerNumber.toString()
                                  : ``;
                            }}
                            isOptionEqualToValue={(option, value) =>
                              value?.id === option?.id
                            }
                            autoHighlight
                            style={{ marginBottom: "5px" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ width: "100px" }}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                error={
                                  oWorkRequestSample.sample &&
                                  !oWorkRequestSample.existingContainerID &&
                                  isFieldRequired(
                                    myWorkRequest.typeName,
                                    "existingContainerID"
                                  )
                                }
                                inputProps={{
                                  ...params.inputProps,
                                  style: { fontSize: sampleGridFontSize },
                                }}
                              />
                            )}
                          />
                        </Box>

                        {oWorkRequestSample.sample !== null &&
                          oWorkRequestSample.sample?.containers.length > 0 &&  (
                            <Box>
                              <IconButton
                                type="button"
                                aria-label="search"
                                onClick={() => {
                                  oWorkRequestSample.sample !== null &&
                                    oWorkRequestSample.sample?.containers.length >
                                    0
                                    ? setLocateContainerIsOpen(true, oWorkRequestSample?.sample?.sampleName)
                                    : setLocateContainerIsOpen(false, '');
                                }}
                              >
                                <SearchIcon style={{ fill: "blue" }} />
                              </IconButton>
                            </Box>
                          )}
                      </Box>
                    </StyledTableCell>
                    <LocateContainer
                      open={locateContainerIsOpen && locateContainerMidas === oWorkRequestSample?.sample?.sampleName}
                      setOpen={setLocateContainerIsOpen}
                      incomingUoMObject={null}
                      midasNumber={oWorkRequestSample?.sample?.sampleName}
                      setSelectedContainer={(value) => {
                        updateData_WorkRequestSample(
                          "existingContainerID",
                          value.id,
                          index
                        );
                      }}
                      updateAllMethods={null}
                      methodInfo={null}
                      allowAllSites={true}
                      onlySearch={(oWorkRequestSample.sample === null || isProcessing || !canEdit)}
                    />
                    {/* UoM */}
                    <StyledTableCell align="right">
                      <StyledAutocomplete
                        renderOption={(props2, option) => (
                          <Option {...props2}>{option.uoMName}</Option>
                        )}
                        disabled={
                          oWorkRequestSample.sample === null || isProcessing
                        }
                        disablePortal
                        noOptionsText={
                          unitOfMeasureLoading
                            ? "Loading UoMs..."
                            : "No UoMs Found"
                        }
                        options={unitOfMeasure}
                        onChange={(e, value) => {
                          updateData_WorkRequestSample(
                            "requestedAmountUoM",
                            value,
                            index
                          );
                        }}
                        value={oWorkRequestSample.requestedAmountUoM}
                        getOptionLabel={(option) =>
                          option?.uoMName
                            ? option.uoMName
                            : unitOfMeasure.length > 0
                              ? unitOfMeasure.find((f) => f.uoMName === option)
                                ?.uoMName
                              : ``
                        }
                        isOptionEqualToValue={(option, value) =>
                          value?.uoMName === option?.uoMName
                        }
                        autoHighlight
                        style={{ marginBottom: "5px" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100px" }}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            error={
                              oWorkRequestSample.sample &&
                              !oWorkRequestSample.requestedAmountUoM
                            }
                            inputProps={{
                              ...params.inputProps,
                              style: { fontSize: sampleGridFontSize },
                            }}
                            InputProps={{ ...params.InputProps }}
                          />
                        )}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ width: "200px", fontSize: sampleGridFontSize }}
                      align="left"
                    >
                      {totalAmt}
                    </StyledTableCell>

                    {/* Description */}
                    <TableCell
                      style={{ width: "200px", fontSize: sampleGridFontSize }}
                      align="left"
                    >
                      {oWorkRequestSample.sample
                        ? oWorkRequestSample.sample.description
                        : ""}
                    </TableCell>

                    {/* PSIMSID */}
                    <StyledTableCell
                      style={{ fontSize: sampleGridFontSize }}
                      align="left"
                    >
                      {oWorkRequestSample.sample &&
                        oWorkRequestSample.sample.psimsid
                        ? oWorkRequestSample.sample.psimsid
                        : ""}
                    </StyledTableCell>
                    {/* returnLocation */}
                    <StyledTableCell
                      style={{ fontSize: sampleGridFontSize }}
                      align="left"
                    >
                      <StyledAutocomplete
                        disabled={oWorkRequestSample.id && oWorkRequestSample.id > 0}
                        disablePortal
                        options={locations}
                        noOptionsText={"Loading Locations..."}
                        onChange={(event, value) => {
                          updateData_WorkRequestSample(
                            "returnLocationName",
                            value?.locationName,
                            index
                          );
                        }}
                        getOptionLabel={(option) =>
                          option?.locationName
                            ? option.locationName
                            : locations.length > 0
                            ? locations.find((f) => f.locationName === option)
                                ?.locationName
                            : ``
                        }
                        value={oWorkRequestSample.returnLocationName}
                        isOptionEqualToValue={(option, value) => value.locationName === option.locationName}
                        autoHighlight
                        autoSelect
                        renderInput={(params) => <TextField {...params} style={{ width: "150px" }} margin="normal" variant="outlined" size="small"
                          InputLabelProps={{ shrink: true }} InputProps={{ ...params.InputProps }} />}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              }
            )}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>

      <Box style={{ marginTop: "1rem" }}>
        <StyledDiv>
          <p style={subsectionTitle}>Test Methods Selection</p>
        </StyledDiv>

        <FormControl>
          <RadioGroup style={{ flexDirection: "row" }}>
            <FormControlLabel
              disabled={isCancelledOrCompleted}
              value={CONTAINERING_METHOD_ENUM.NON_SPLIT}
              key={CONTAINERING_METHOD_ENUM.NON_SPLIT}
              label="Make NonSplit"
              control={
                <Radio
                  checked={
                    testJson.containeringMethod ===
                    CONTAINERING_METHOD_ENUM.NON_SPLIT
                  }
                  onChange={() => {
                    setTestJson((prev) => ({
                      ...prev,
                      containeringMethod: CONTAINERING_METHOD_ENUM.NON_SPLIT,
                    }));
                  }}
                />
              }
            />
            <HelpIcon
              style={{
                marginTop: ".5rem",
                marginRight: "1rem",
                color: "blue",
              }}
              onClick={(e) =>
                openModalMessages("Container Info", globalMessages.NonSplitMsg)
              }
            ></HelpIcon>
            <FormControlLabel
              disabled={isCancelledOrCompleted}
              value={CONTAINERING_METHOD_ENUM.MIDAS}
              key={CONTAINERING_METHOD_ENUM.MIDAS}
              label="MIDAS Recommendation"
              control={
                <Radio
                  checked={
                    testJson.containeringMethod ===
                    CONTAINERING_METHOD_ENUM.MIDAS
                  }
                  onChange={() => {
                    setTestJson((prev) => ({
                      ...prev,
                      containeringMethod: CONTAINERING_METHOD_ENUM.MIDAS,
                    }));
                  }}
                />
              }
            />
            <HelpIcon
              style={{ marginTop: ".5rem", color: "blue" }}
              onClick={(e) =>
                openModalMessages("Container Info", globalMessages.MidasMsg)
              }
            ></HelpIcon>
          </RadioGroup>
        </FormControl>

        <MethodSelection
          width="100%"
          selectedMethods={testJson.methods}
          setSelectedMethods={(value) => {
            setNeedsRecommendationsAgain(true);
            setRecommendationMessage(
              !containerRecommendation?.length
                ? RECOMMENDATIONS_ERROR_MESSAGES.NO_CONTAINERS
                : RECOMMENDATIONS_ERROR_MESSAGES.REASK_RECOMMENDATIONS
            );
            setTestJson((prev) => ({ ...prev, methods: value }));

            filterContainerRecommendation(value)
          }}
          showCompletionDate={false}
          showContainerGrouping={false}
          incomingTemplate={null}
          showSearchTemplateName={true}
          showEstimateAndPriority={true}
          maxMethodsAllowed={999}
          isReadOnly={isProcessing || isCancelledOrCompleted}
        />
      </Box>

      <StyledDiv>
        <p style={subsectionTitle}>Sample Quantity Info</p>
      </StyledDiv>
      {!isProcessing && (
        <>
          <GlobalButton
            sx={{ marginTop: "10px", marginBottom: "10px" }}
            disabled={
              !testJson.methods.length ||
              testJson.methods.some((x) => !x.methodFacility) ||
              !myWorkRequest.workRequestSamples.filter((x) => x.sample).length ||
              isCancelledOrCompleted
            }
            variant="contained"
            onClick={handleGetRecommendation}
          >
            Get Recommendations
          </GlobalButton>
          <label style={{ marginLeft: "10px", color: "red" }}>
            {needsRecommendationsAgain ? recommendationMessage : ""}
          </label>
        </>
      )}

      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={MySampleInfoColsQtySelection}
        blankFirstHeader={false}
        tableId="midasNumbersTable"
        rowLength={
          (isProcessing
            ? myWorkRequest.workRequestSamples.filter(f => f.newContainer)
            : containerRecommendation
          )?.length
        }
        enablePaging={false}
        rowsPerPage={0}
        page={0}
        handleChangePage={null}
        handleChangeRowsPerPage={null}
        noDataFoundMessage="No MIDAS Numbers Found"
        enableAddIcon={false}
        addFunction={() => { }}
        addToolTipText="Add new sample"
        isDataLoading={false}
      >
        <StyledTableBody>
          {(isProcessing
            ? myWorkRequest.workRequestSamples.filter(f => f.newContainer)
            : containerRecommendation
          )?.map((oWorkRequestSample, index) => {
            if (!unitOfMeasure.length) return <></>;
            const totalAmt = getConvertedCurrentAmount(
              oWorkRequestSample,
              false
            );

            let currentRecommendationRef = recommendationRef.current[index];

            let currentRecommendationRefAmount =
              currentRecommendationRef?.newContainer
                ? ConvertUOMs(
                  oWorkRequestSample.sample,
                  oWorkRequestSample.sample.substance,
                  currentRecommendationRef?.newContainer?.currentAmount,
                  currentRecommendationRef?.newContainer?.uom,
                  oWorkRequestSample.unitOfMeasure
                )
                : 0;
            let minimumReq = isProcessing
              ? `${oWorkRequestSample.newContainer?.tests?.reduce(
                (acc, value) =>
                  acc +
                  ConvertUOMs(
                    oWorkRequestSample.sample,
                    oWorkRequestSample.sample.substance,
                    GetRequiredSampleSize(value),
                    unitOfMeasure.find(
                      (x) =>
                        x.uoMName === GetRequiredSampleSizeUoM(value)
                    ),
                    oWorkRequestSample.unitOfMeasure
                  ),
                0
              )} ${oWorkRequestSample.requestedAmountUoM}`
              : `${currentRecommendationRefAmount?.toFixed(4)} ${oWorkRequestSample.requestedAmountUoM
              }`;

            return (
              <StyledTableRow key={`WRSamplesTable~Row${index}`}>
                <StyledTableCell align="left">
                  {oWorkRequestSample?.sample?.sampleName}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {oWorkRequestSample.newContainer?.tests?.map((x) => (
                    <div>
                      {x.methodFacility?.methodName} /
                      {x.methodFacility?.testFacilityAbv}
                    </div>
                  )) ?? ""}
                </StyledTableCell>

                {/* UoM */}
                <StyledTableCell align="right">
                  <StyledAutocomplete
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option.uoMName}</Option>
                    )}
                    disabled={
                      oWorkRequestSample.sample === null || isProcessing
                    }
                    disablePortal
                    noOptionsText={
                      unitOfMeasureLoading ? "Loading UoMs..." : "No UoMs Found"
                    }
                    options={unitOfMeasure}
                    onChange={(e, value) => {
                      updateData_ContainerRecommendation(
                        "requestedAmountUoM",
                        value,
                        index
                      );
                    }}
                    value={oWorkRequestSample.requestedAmountUoM}
                    getOptionLabel={(option) =>
                      option?.uoMName
                        ? option.uoMName
                        : unitOfMeasure.length > 0
                          ? unitOfMeasure.find((f) => f.uoMName === option)
                            ?.uoMName
                          : ``
                    }
                    isOptionEqualToValue={(option, value) =>
                      value?.uoMName === option?.uoMName
                    }
                    autoHighlight
                    style={{ marginBottom: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "100px" }}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={
                          oWorkRequestSample.sample &&
                          !oWorkRequestSample.requestedAmountUoM
                        }
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: sampleGridFontSize },
                        }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">{minimumReq}</StyledTableCell>

                <StyledTableCell align="left">
                  <TextField
                    style={{ width: "75px", marginTop: "5px" }}
                    disabled={
                      oWorkRequestSample.sample === null || isProcessing
                    }
                    size="small"
                    margin="dense"
                    variant="outlined"
                    inputProps={{ style: { fontSize: sampleGridFontSize } }}
                    error={
                      (oWorkRequestSample.sample &&
                        !oWorkRequestSample.requestedAmount) ||
                      toFixedFourDecimals(oWorkRequestSample.requestedAmount) >
                      toFixedFourDecimals(totalAmt.split(" ")[0]) ||
                      toFixedFourDecimals(oWorkRequestSample?.requestedAmount) <
                      toFixedFourDecimals(currentRecommendationRefAmount)
                    }
                    InputLabelProps={{ shrink: true }}
                    value={oWorkRequestSample.requestedAmount ?? ""}
                    onChange={(e) => {
                      var numberRegex = /^\d*\.?\d*$/;
                      if (
                        numberRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        const totalAmtValue = parseFloat(
                          totalAmt.split(" ")[0]
                        );
                        const currentValue = parseFloat(e.target.value) ?? null;
                        let value = null;
                        if (currentValue !== null && !isNaN(currentValue)) {
                          value =
                            currentValue > totalAmtValue
                              ? totalAmtValue
                              : currentValue;
                        }

                        updateData_ContainerRecommendation(
                          "requestedAmount",
                          value,
                          index
                        );
                      }
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>

      <ModalMessages
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}
      >
        <label>{modalMessagesText}</label>
      </ModalMessages>
    </Box>
  );
}
